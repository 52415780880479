import(/* webpackMode: "eager" */ "/home/nr/prog/doto/solutions/node_modules/.pnpm/next@14.2.3_@babel+core@7.25.8_@opentelemetry+api@1.9.0_@playwright+test@1.48.1_babel-plugin-_2dtpeyhcu64xakvprow2hp2chi/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/home/nr/prog/doto/solutions/node_modules/.pnpm/next@14.2.3_@babel+core@7.25.8_@opentelemetry+api@1.9.0_@playwright+test@1.48.1_babel-plugin-_2dtpeyhcu64xakvprow2hp2chi/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/nr/prog/doto/solutions/node_modules/.pnpm/next@14.2.3_@babel+core@7.25.8_@opentelemetry+api@1.9.0_@playwright+test@1.48.1_babel-plugin-_2dtpeyhcu64xakvprow2hp2chi/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/nr/prog/doto/solutions/node_modules/.pnpm/next@14.2.3_@babel+core@7.25.8_@opentelemetry+api@1.9.0_@playwright+test@1.48.1_babel-plugin-_2dtpeyhcu64xakvprow2hp2chi/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/nr/prog/doto/solutions/node_modules/.pnpm/next@14.2.3_@babel+core@7.25.8_@opentelemetry+api@1.9.0_@playwright+test@1.48.1_babel-plugin-_2dtpeyhcu64xakvprow2hp2chi/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/nr/prog/doto/solutions/node_modules/.pnpm/next@14.2.3_@babel+core@7.25.8_@opentelemetry+api@1.9.0_@playwright+test@1.48.1_babel-plugin-_2dtpeyhcu64xakvprow2hp2chi/node_modules/next/dist/client/components/render-from-template-context.js");
